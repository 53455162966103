export const ROUTES = {
  medical_staff: {
    subscriptions: '/v2/subscriptions',
    dashboard: '/v2/medical_staff/dashboard',
    patients: '/v2/medical_staff/patients',
    patient_shared: '/v2/medical_staff/patients_shared',
    send_files: '/v2/medical_staff/send_files',
    medical_record_fields: '/v2/medical_staff/medical_record_fields',
    statistics: '/v2/medical_staff/statistics',
    profile: '/v2/medical_staff/profile',
    cerfa_templates: {
      list: '/v2/medical_staff/document_templates',
      update: '/v2/medical_staff/cerfa_templates/:id',
    }
  }
};
