import React, { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { RequestError } from '@/services/request/request';
import useLogIn from '@/auth/hooks/useLogIn';
import LoginForm from './forms/login/LoginForm';
import TwoFactorForm from './forms/two-factor/TwoFactorForm';

import { TwoFactorProps, TwoFactorMethod } from './types';
import { CONTEXT } from '@/packages/back-end/pro-sante-connect-profile';
import useProSanteConnect from '@/auth/hooks/useProSanteConnect';
import useURLParams from '@/utils/hooks/useURLParams';
import TwoColumns from '@/auth/structure/two-columns/TwoColumns';
import { ButtonProps } from 'instamed-styleguide/ui/atoms/buttons/button/Button';
import { useHistory } from 'react-router-dom';

export type LoginProps = {
  // only login and no other action available
  // usefull for @pages/create-patient
  simple?: boolean;
  subtitle?: string;
  cancelBtn?: ButtonProps;
};

/**
 * Login page handle connection: logIn and two factor
 */
export const Login: FC<LoginProps> = ({
  simple = true,
  subtitle,
  cancelBtn
}) => {
  const { t } = useTranslation('auth');
  const [twoFactorConfig, setTwoFactorConfig] = useState<
    TwoFactorProps | undefined
  >();

  const history = useHistory();

  const [legacyError, setLegacyError] = useState<boolean>();

  const { path } = useURLParams(['path']);

  const { loading: pscLoading, onProSanteConnect } = useProSanteConnect({
    context: CONTEXT.LOGIN,
    redirectPath: path,
  });

  const { loading, logIn, legacyLogIn, logged, error } = useLogIn();

  const onSubmit = useCallback(
    async (args) => {
      try {
        await logIn(args);
      } catch (e) {
        const requestError = e as RequestError;

        if (requestError?.code === 406 && requestError?.response?.data?.token) {
          setTwoFactorConfig({
            token: requestError.response.data?.token,
            method: requestError.response.headers?.[
              'www-authenticate'
            ] as TwoFactorMethod,
            rememberMe: args.rememberMe,
            username: args.username,
          });
          return;
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [logIn]
  );

  const setAuthTokens = useCallback(
    (args) => {
      legacyLogIn(args).catch(() => {
        setLegacyError(true);
      });
    },
    [legacyLogIn]
  );

  const goBackBtn = useMemo(
    () => ({
      children: t('form.back'),
      onClick: () => history.push('/'),
    }),
    [history,t]
  );

  if (!twoFactorConfig) {
    return (
      <TwoColumns cancelBtn={cancelBtn || goBackBtn}>
        <LoginForm
          onProSanteConnect={
            !simple ? onProSanteConnect : undefined
          }
          loading={loading || logged}
          proSanteConnectLoading={pscLoading}
          subtitle={subtitle}
          creation={!simple}
          error={error?.message}
          onSubmit={onSubmit}
        />
      </TwoColumns>
    );
  }

  return (
    <TwoColumns cancelBtn={cancelBtn || goBackBtn}>
      <TwoFactorForm
        {...twoFactorConfig}
        error={legacyError ? t('form.login.legacy.error') : undefined}
        setAuthTokens={setAuthTokens}
      />
    </TwoColumns>
  );
};

export default Login;
