import React, { FC } from 'react';

import TwoColumnsComp, {
  TwoColumnsProps as TwoColumnsCompProps,
} from '@ui/templates/two-columns/TwoColumns';

import NotConnectedFooter from '@ui/organisms/footers/NotConnectedFooter';
import NotConnectedNavigationBar from '@ui/organisms/navigation-bar/NotConnectedNavigationBar';
import TypingLoginLabels from '../typing-login-labels/TypingLoginLabels';
import { useHistory } from 'react-router-dom';

export type TwoColumnsProps = Pick<
  TwoColumnsCompProps,
  'reverse' | 'size' | 'footerRight' | 'cancelBtn'
> & {
  testid?: string;
};

const TwoColumns: FC<TwoColumnsProps> = ({
  size,
  testid,
  children,
  cancelBtn,
  footerRight = <NotConnectedFooter />,
}) => {

  const history = useHistory();

  return (
    <div className='flex overflow-hidden flex-col h-screen'>
      <NotConnectedNavigationBar
        logo={{
          onClick: (e) => {
            e.preventDefault();
            history.push('/');
            return false;
          },
          link: '/' }}
        cancelBtn={cancelBtn}
      />
      <TwoColumnsComp
        containerClassName='mt-16 lg:mt-20 '
        size={size}
        data-testid={testid}
        footerRight={footerRight}
      >
        <TypingLoginLabels />
        {children}
      </TwoColumnsComp>
    </div>
  );
};

export default TwoColumns;
